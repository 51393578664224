<template>
  <div>
    <div class="banner">
      <warning-tip code="ORDERTEST"></warning-tip>
      <main-top title="淘客订单查询" desc="淘客订单号检测某个订单号是否使用淘客下单" spend="ORDERTEST">
        <!-- 使用搜索框组件 -->
        <InputView :CanYouClick="CanYouClick" placeholder-name="请输入商品订单" @sendSearchName="getSearchName"></InputView>
      </main-top>

      <bordure-row title="淘客订单查询历史记录" style="margin-bottom:30px;">
      <!-- table -->
      <el-table style="border: 1px solid #e5e5e5; border-bottom: 0" size="mini" :data="tableData" max-height="500" :header-cell-style="{
          background: '#f8f8f8',
          'font-family': 'PingFangSC-Regular',
          color: '#666666 !important',
          'letter-spacing': '0',
          'text-align': 'center',
          'font-weight': 'normal',
        }">
        <el-table-column label="查询单号" prop="orderId" align="center"></el-table-column>
        <el-table-column label="查询结果" prop="orderId" align="center">
          <template slot-scope="scope">
            <span :style="scope.row.resultValue == 'no' ? 'color: red' : ''">{{
              scope.row.resultValue == "no" ? "非淘客单" : "淘客单"
            }}</span>
          </template>
        </el-table-column>
        <el-table-column label="查询时间" prop="createTime" align="center"></el-table-column>
        <!-- <el-table-column label="用户头像"  align="center">
          <template slot-scope="scope">
            <img style="width: 80px; height: 80px" :src="scope.row.resultValue" alt />
          </template>
        </el-table-column>-->
      </el-table>
      </bordure-row>
      <!-- 讲解文案区域 -->
      <Introduce :introduce="introduce"></Introduce>
    </div>
  </div>
</template>
<script>
  import InputView from "../public/InputView";
  import Introduce from "../public/Introduce";
  import {
    queryOrder,
    queryOrderList
  } from "../../request/api"; //这里是引入请求
  export default {
    data() {
      return {
        CanYouClick: true,
        tableData: [{
          createTime: "2021-04-14 15:18:58(模板)",
          id: 1,
          orderId: "1636490163884758709(模板)",
          resultValue: "no",
          userId: 61,
        }, ],
        introduce: [{
          title: "有什么作用？",
          content: [
            "1.走淘客返利的买家不计算搜索权重，也就是白做单。",
            "2.走淘客返利的卖家会扣双倍佣金，即放单佣金和淘客佣金。",
          ],
        }, ], //介绍相关数组
      };
    },
    components: {
      InputView,
      Introduce,
    },
    created() {
      if (this.$store.state.token != null) {
        this.getQueryOrderList();
      }
    },
    methods: {
      //   子传父的方法
      getSearchName(value) {
        this.CanYouClick = false; //不允许再点击搜索
        this.$store.commit("alterLoadContent", "正在该商品订单的相关数据");
        queryOrder({
          order_id: value
        }).then((data) => {
          this.getQueryOrderList();
        }).finally(() => {
          this.$store.commit("alterJumpFlag", false); //关掉遮罩层
          this.CanYouClick = true; //允许再次搜索
        });
      },
      // 获取列表的方法
      getQueryOrderList() {
        queryOrderList().then((data) => {
          this.tableData = data.data;
        });
      },
    },
  };
</script>

<style lang="less" scoped>
  .multipleSearch {
    padding: 80px 200px 40px;
    text-align: center;
  }

  .multipleSearchBottom {
    padding: 0 80px 100px;
    text-align: center;
  }

  .btn {
    display: flex;
    justify-content: flex-end;
    padding: 0 300px;
  }
</style>