var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"banner"},[_c('warning-tip',{attrs:{"code":"ORDERTEST"}}),_c('main-top',{attrs:{"title":"淘客订单查询","desc":"淘客订单号检测某个订单号是否使用淘客下单","spend":"ORDERTEST"}},[_c('InputView',{attrs:{"CanYouClick":_vm.CanYouClick,"placeholder-name":"请输入商品订单"},on:{"sendSearchName":_vm.getSearchName}})],1),_c('bordure-row',{staticStyle:{"margin-bottom":"30px"},attrs:{"title":"淘客订单查询历史记录"}},[_c('el-table',{staticStyle:{"border":"1px solid #e5e5e5","border-bottom":"0"},attrs:{"size":"mini","data":_vm.tableData,"max-height":"500","header-cell-style":{
        background: '#f8f8f8',
        'font-family': 'PingFangSC-Regular',
        color: '#666666 !important',
        'letter-spacing': '0',
        'text-align': 'center',
        'font-weight': 'normal',
      }}},[_c('el-table-column',{attrs:{"label":"查询单号","prop":"orderId","align":"center"}}),_c('el-table-column',{attrs:{"label":"查询结果","prop":"orderId","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',{style:(scope.row.resultValue == 'no' ? 'color: red' : '')},[_vm._v(_vm._s(scope.row.resultValue == "no" ? "非淘客单" : "淘客单"))])]}}])}),_c('el-table-column',{attrs:{"label":"查询时间","prop":"createTime","align":"center"}})],1)],1),_c('Introduce',{attrs:{"introduce":_vm.introduce}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }